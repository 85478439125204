import React from "react";
import Navbar from "../../component/Navbar/Navbar";
import PernosalLoan from "../../component/Personalloan/Personalloan";
import Expertloan from "../../component/Expertloan/Expertloan";
import Securely from "../../component/Securely/Securely";
import Header from "../../component/Header/Header";
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className=" ">
      <Helmet>
        <title>Expert Loan</title>
        <meta name="description" content="This Page is for geting the loan" />
        <meta name="keywords" content="loan" />
        <link rel="icon" href="/mortgage-loan" type="image/x-icon" />
      </Helmet>
      <Navbar />
      <Header />
      <PernosalLoan />
      <Expertloan />
      <Securely />
    </div>
  );
}

export default Home;
