 
 import { GrSettingsOption } from "react-icons/gr";
 import { FaCalendarAlt } from "react-icons/fa";
 import { FaThumbsUp } from "react-icons/fa";
 import { BsGraphUpArrow } from "react-icons/bs";
 import { FaLock } from 'react-icons/fa';
 export const NavbarLink = [
    { id: 1, name: 'Home', isActive: false },
    { id: 2, name: 'How it works', isActive: false },
    { id: 3, name: 'FAQs', isActive: false },
  
  ];

 export const features = [
    {
      title: "We Work For You",
      description:
        "expertloan manages an expansive network of lenders to provide our users with premium access to personal loans. Request up to $15,000 through one simple online form, 24 hours a day, 7 days a week. Our lenders offer both installment and short-term loans, connecting you with the loan that works for you.",
      icon: <GrSettingsOption/>,
    },
    {
      title: "Money As Soon As Tomorrow",
      description:
        "Whether you are behind on bills, have an unexpected expense or simply want to take a vacation - expertloan provides fast access to the funds you need so you can accomplish your financial goals without delay. Once you are connected with a lender in our network, funds are typically deposited as soon as the next business day provided the request is received before 5pm CST.",
      icon:<FaCalendarAlt/>,
    },
    {
      title: "All Credit Types Accepted",
      description:
        "expertloan’s network of lenders offers a full range of loans for people with excellent credit as well as bad credit. If you are 18 years or older and have a regular source of income, you can use our online form.",
      icon: <FaThumbsUp/>,
    },
    {
      title: "Build Your Credit",
      description:
        "Several of the lenders in expertloan's network report on-time payment to credit agencies, which can ultimately improve your credit score.",
      icon: <BsGraphUpArrow/>,
    },
];
export const featureser = [
  {
    title: "We Work For You",
    description:
      "expertloan manages an expansive network of lenders to provide our users with premium access to personal loans. Request up to $15,000 through one simple online form, 24 hours a day, 7 days a week. Our lenders offer both installment and short-term loans, connecting you with the loan that works for you.",
    icon: <GrSettingsOption/>,
  },
  {
    title: "Money As Soon As Tomorrow",
    description:
      "Whether you are behind on bills, have an unexpected expense or simply want to take a vacation - expertloan provides fast access to the funds you need so you can accomplish your financial goals without delay. Once you are connected with a lender in our network, funds are typically deposited as soon as the next business day provided the request is received before 5pm CST.",
    icon:<FaCalendarAlt/>,
  },
  {
    title: "All Credit Types Accepted",
    description:
      "expertloan’s network of lenders offers a full range of loans for people with excellent credit as well as bad credit. If you are 18 years or older and have a regular source of income, you can use our online form.",
    icon: <FaThumbsUp/>,
  },
  {
    title: "your Security & Privacy are important to us",
    description:
      "All data processed through this site is encrypted with the latest 256-bit SSL encryption technology to ensure your information is safe.",
    
    icon: <FaLock/>,
  },


];