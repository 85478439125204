import React, { useState } from "react";
import { HiLockClosed } from "react-icons/hi";
import { collection, addDoc } from "firebase/firestore"; 
import db from "../../service/firebase"

function Header() {
  const [formData, setFormData] = useState({
    cashNeeded: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    bankName: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // Success message state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null); // Reset error state before submission
    setSuccess(null); // Reset success state before submission

    // Basic validation
    if (!formData.firstName || !formData.lastName || !formData.email) {
      setError("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    try {
      // Get a reference to the Firestore collection
      const loanCollectionRef = collection(db, "loan");

      // Save the form data to Firestore
      const data = await addDoc(loanCollectionRef, formData);
      console.log(data,"success")

      // Show success message
      setSuccess("Loan application submitted successfully!");

      // Reset the form after submission
      setFormData({
        cashNeeded: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dateOfBirth: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        bankName: "",
      });
    } catch (error) {
      console.log(error);
      setError("An error occurred while saving the form data.");
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className="relative flex z-30 justify-center items-center text-center bg-custom-pattern2 bg-cover w-full">
      <div className="absolute inset-0 bg-black h-full opacity-70 md:opacity-80 lg:opacity-40 z-0"></div>
      <div className="z-30 mt-0 md:mt-14 lg:mt-20 max-w-6xl w-full mx-[14px] md:mx-[50px] lg:mx-[20px]">
      <h1 className="text-custom-navbar-color text-2xl md:text-4xl font-bold font-montserrat uppercase">
          Personal Loans
        </h1>
        <h2 className="text-white text-lg md:text-2xl mt-1 font-bold font-montserrat uppercase">
          Made Simple and Fast.
        </h2>
        <p className="text-white text-xs md:text-base font-light font-montserrat my-2">
          Borrow between $100 and $15,000 as soon as tomorrow.
        </p>
        
        {/* Form */}
        <form onSubmit={handleSubmit} className="relative bg-cover rounded-lg px-4 md:px-10 lg:px-20 py-3 z-10">
        <p className="text-white text-xs md:text-sm font-light font-montserrat mb-4">
          Start our 5-minute online form by telling us how much you need.
        </p>
          
          {/* Form Fields */}
          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Cash Needed</span>
              <select name="cashNeeded" value={formData.cashNeeded} onChange={handleChange} className="p-2 border-2 border-gray-400 rounded">
                <option value="">Select Amount</option>
                <option value="$500-$1,000">$500-$1,000</option>
                <option value="$1,001-$2,500">$1,001-$2,500</option>
                <option value="$2,501-$5,000">$2,501-$5,000</option>
                <option value="$5,001-$15,000">$5,001-$15,000</option>
              </select>
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">First Name*</span>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                className="p-2 border-2 border-gray-400 rounded"
                required
              />
            </label>
            
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Last Name*</span>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                className="p-2 border-2 border-gray-400 rounded"
                required
              />
            </label>

            <label className="flex flex-col w-full lg:w-[155%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Email*</span>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="p-2 border-2 border-gray-400 rounded"
                required
              />
            </label>
          </div>

          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Phone</span>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Date of Birth</span>
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Address</span>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>
          </div>

          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">City</span>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">State</span>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="State"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>

            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Zip Code</span>
              <input
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                placeholder="Zip Code"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>
          </div>

          <div className="lg:flex lg:space-x-6 my-3">
            <label className="flex flex-col w-full lg:w-[95%]">
              <span className="mb-3 text-start text-[13px] font-[800] font-montserrat text-white">Bank Name</span>
              <input
                type="text"
                name="bankName"
                value={formData.bankName}
                onChange={handleChange}
                placeholder="Bank Name"
                className="p-2 border-2 border-gray-400 rounded"
              />
            </label>
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="mt-5 bg-custom-navbar-color text-white py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Header;
