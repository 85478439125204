import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavbarLink } from "../../data/data";
import Header from "../Header/Header";
import { RxHamburgerMenu } from "react-icons/rx";

function Navbar() {
  const [activeButton, setActiveButton] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (id) => {
    setActiveButton(id);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {/* Black overlay */}
      <div className="absolute inset-0 opacity-70 md:opacity-80 lg:opacity-40 z-0"></div>

      {/* Main content */}
      <div
        className={`fixed z-[100] grid grid-cols-12 items-center py-4 w-full px-[1rem] md:px-[4rem] lg:px-[7rem] xl:px-[7rem] 2xl:px-[10rem] ${
          isScrolled ? "bg-white text-black" : "text-white"
        }`}
        style={{
          boxShadow:
            isScrolled &&
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}
      >
        <div className="col-span-5 flex">
          <img
            src="https://res.cloudinary.com/djlpb1ld5/image/upload/v1729463012/logo-white_se6hpj.png"
            alt=""
            className="h-[30px] sm:h-[50px] w-full"
          />
        </div>
        <button
          className="px-2 py-1 block md:hidden bg-custom-navbar-color text-white fixed rounded-sm right-4"
          onClick={toggleDrawer}
        >
          <RxHamburgerMenu className="font-bold text-[20px]" size="25" />
        </button>
        <div className="col-span-7 hidden md:block">
          <div className="flex justify-end space-x-2">
            {NavbarLink.map((button) => (
              <button
                key={button.id}
                onClick={() => handleClick(button.id)}
                className={`px-4 py-2 whitespace-nowrap text-[14px] font-[500] font-montserrat ${
                  activeButton === button.id
                    ? "text-custom-navbar-color"
                    : isScrolled
                    ? "text-black"
                    : "text-white"
                }`}
              >
                {button.name}
              </button>
            ))}
            <button
              onClick={() => handleClick(5)}
              className={`px-2 py-1 border whitespace-nowrap text-[14px] font-[500] font-montserrat rounded-md ${
                activeButton === 5
                  ? "bg-custom-navbar-color border-custom-navbar-color text-white"
                  : isScrolled
                  ? "border text-black"
                  : "border text-white"
              }`}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>

      {/* Drawer Menu */}
      <div>
        <div
          className={`fixed top-0 right-0 h-1/3 z-40 w-full bg-custom-navbar-color text-white transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <button
            className="px-4 py-2 text-center font-montserrat bg-white text-black"
            onClick={toggleDrawer}
          >
            Close Drawer
          </button>
          <div className="justify-center text-[20px] text-center font-montserrat items-center space-y-5">
            <p className={isScrolled ? "text-black" : "text-white"}>Home</p>
            <p className={isScrolled ? "text-black" : "text-white"}>
              How it Works
            </p>
            <p className={isScrolled ? "text-black" : "text-white"}>FAQs</p>
            <p className={isScrolled ? "text-black" : "text-white"}>Get Started</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
