import React from 'react'


function Expertloan() {
  return (
<div
  className="relative bg-transparent h-[350px] flex flex-col justify-center items-center"
>
  {/* Gradient overlay with opacity using ::before */}
  <div
    style={{
      background: "linear-gradient(28deg, rgba(116, 82, 144, 0.5) 60%, rgba(224, 216, 191, 0.8) 91%)",
    }}
    className="absolute inset-0 z-0 lg:opacity-50 opacity-40"
  />

  {/* Content */}
  <div className="relative z-10 flex flex-col px-10 justify-center items-center">
    <h1 className="text-white text-center  font-montserrat text-[18px] lg:text-[34px] font-[400] mb-2">
      You’re in great hands with expertloan.
    </h1>
    <h2 className="text-white text-center font-montserrat text-[12px] lg:text-[15px] font-[400]">
      Our team is dedicated to providing real financial solutions for our users each and every day.
    </h2>
    <p className="text-white text-center font-montserrat text-[12px] lg:text-[15px] font-[600] mb-4">
      Request up to $15,000 and receive a decision in real-time.
    </p>
  </div>

  <button className="bg-custom-button-purpal text-white hover:bg-custom-navbar-color uppercase text-[14.95px] font-montserrat py-3 px-7 rounded-md z-10">
    GET started
  </button>
</div>

  )
}

export default Expertloan