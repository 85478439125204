import React from 'react'
import { features,featureser } from '../../data/data'
import { FaLock } from 'react-icons/fa';

function Personalloan() {
  return (
    <div className="bg-white  ">
      <div className=''>
         <h1 className="text-[18px] xl:text-[30px] text-center text-gray-600  lgtext-gray-800 px-3 py-7 lg:py-14 font-[400] font-montserrat">
          Do More With a Personal Loan That Can Meet Your Needs.
        </h1> 
         </div>
       <div className='lg:flex justify-center hidden items-center'>
       <div className="grid grid-cols-12  max-w-6xl w-full">
          {features.map((feature, index) => (
            <div className="col-span-12  md:col-span-6" key={index}>
              <div className="hidden  sm:flex justify-center ">
                <div className="text-[39px] font-[400] text-custom-navbar-color p-4">
                  {feature.icon}
                </div>
                <div className=" text-gray-600  p-4">
                  <p className='text-[13px] font-[700] text-gray-600'>{feature.title}</p>
                  <span >{feature.description}</span>
                </div>
              </div>
              <div className="block sm:hidden  ">
                <div className="text-[39px] flex justify-center font-[400] text-custom-navbar-color p-4">
                  {feature.icon}
                </div>
                <div className=" text-gray-600   items-center p-4">
                  <div >
                  <p className='text-[13px] text-center  font-[700] text-gray-600'>{feature.title}</p>
                  <p className='text-center'>{feature.description}</p>
                  </div>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
       </div>
       <div className='flex justify-center  lg:hidden items-center'>
        <div className="grid grid-cols-12   max-w-7xl w-full">
          {featureser.map((feature, index) => (
            <div className="col-span-12  md:col-span-6" key={index}>
              <div className="hidden  sm:flex justify-center ">
                <div className="text-[39px] font-[400] text-custom-navbar-color p-4">
                  {feature.icon}
                </div>
                <div className=" text-gray-600  p-4">
                  <p className='text-[13px] font-[700] text-gray-600'>{feature.title}</p>
                  <span >{feature.description}</span>
                </div>
              </div>
              <div className="block sm:hidden  ">
                <div className="text-[39px] flex justify-center font-[400] text-custom-navbar-color p-4">
                  {feature.icon}
                </div>
                <div className=" text-gray-600   items-center p-4">
                  <div >
                  <p className='text-[13px] text-center  font-[700] text-gray-600'>{feature.title}</p>
                  <p className='text-center'>{feature.description}</p>
                  </div>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
        
        <div className="md:flex items-center hidden  justify-center bg-white">
        <div className="flex items-center mt-6">
      <FaLock className="h-4 w-4 text-custom-navbar-color mr-2" /> 
      <span className=" text-[20px] font-montserrat text-gray-600">
        Your Security & Privacy are important to us
      </span>
    </div>
        </div>
        <div className="text-center text-[13px] hidden md:block text-gray-600 font-montserrat items-center ">
          <p>
            All data processed through this site is encrypted with the latest <br/><span className="font-semibold "> 256-bit SSL encryption technology 
            </span>
           
             to ensure your information is safe.
          </p>
         
        </div>
        <div className="flex items-center justify-center py-5 ">
          <img
            src="https://res.cloudinary.com/dzumghpns/image/upload/v1729486628/memberseal_qznsms.png"
            alt="Member Seal"
            className="w-auto h-30"
          />
        </div>
        <div className="flex items-center justify-center px-1 lg:px-20">
          <p className=" text-center text-[13px] font-[400]  px-1 lg:px-[12px] font-montserrat lg:w-2/3">
            When you see the OLA seal, you can trust you’re working with a
            company committed to the highest standards of conduct, dedicated to
            ensuring the best possible experience for their users, fully
            compliant with federal law, and working hard to protect consumers
            from fraud.
          </p>
        </div> 
      </div>
  )
}

export default Personalloan