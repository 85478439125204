import { useState ,useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home/Home';
import {NavbarLink} from './data/data';
import { RxHamburgerMenu } from "react-icons/rx";

function App() {
 
  return (
    <div >
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
   
  </div>
  );
}

export default App;
