// src/service/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration (replace with your own config)
// const firebaseConfig = {
//     apiKey: "AIzaSyBpSbi1v-ieRXVSGcxokXWr-XGda8P1YnA",
//     authDomain: "testproject-15f42.firebaseapp.com",
//     projectId: "testproject-15f42",
//     storageBucket: "testproject-15f42.firebasestorage.app",
//     messagingSenderId: "355621100727",
//     appId: "1:355621100727:web:61af571352457a08758132",
//     measurementId: "G-PL00SX4JG8"
//   };


const firebaseConfig = {
  apiKey: "AIzaSyAH0X-RrGawlSgzyDJafO_60cz9J_lKoK4",
  authDomain: "loan-app-7b488.firebaseapp.com",
  projectId: "loan-app-7b488",
  storageBucket: "loan-app-7b488.firebasestorage.app",
  messagingSenderId: "522357252267",
  appId: "1:522357252267:web:27a153013f20ce27b357ee",
  measurementId: "G-0SK8YVWMYW"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
